div.tox-tinymce {
  z-index: 0;
}
.dark input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(2);
  color: red;
}
@media (max-width: 768px) {
  /* Adjust breakpoint as needed */
  .desktop-only {
    display: none !important;
  }
}
